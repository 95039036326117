import React from "react"
import styled from "styled-components"
import { colors } from "../../theme"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "gatsby"

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #687497;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open, isScrolled }) =>
    open && !isScrolled ? "translateX(0)" : "translateX(-100%)"};

  a {
    font-size: 2rem;
    text-transform: uppercase;
    margin: 2rem 0;
    letter-spacing: 0.5rem;
    color: ${colors.white};
    transition: color 0.3s linear;

    &:hover {
      cursor: pointer;
    }
  }
`

const StyledLink = styled(Link)`
  font-family: DM Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.528px;
  color: ${colors.white};
  transition: color 0.25s ease-in-out;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: #ef5da8;
  }
`

export const Menu = ({ open, setOpen, scrollY }) => {
  const menuItems = [
    { title: "Apie mus", page: "/apie-mus" },
    { title: "Paslaugos", page: "/paslaugos" },
    { title: "Kontaktai", page: "/kontaktai" },
    { title: "Pasiūlymai", page: "/pasiulymai" },
  ]

  return (
    <StyledMenu open={open} scrollY={scrollY}>
      {menuItems.map(item => (
        <StyledLink
          to={item.page}
          onClick={() => {
            setOpen(false)
          }}
        >
          {item.title}
        </StyledLink>
      ))}
      <a
        onClick={() => {
          setOpen(false)
          scrollTo("#form", "center")
        }}
      >
        Užklausa
      </a>
    </StyledMenu>
  )
}
