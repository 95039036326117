import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Container } from "../../../theme/helpers"
import { colors, mediaQuery } from "../../../theme"
import { ReactComponent as LogoSvg } from "../../../images/logo.svg"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useScroll, useEvent } from "../../../core/hooks"
import { rem } from "polished"
import { Burger } from "../../burger"
import { Menu } from "../../menu"
import { Button } from "../../button"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const HeaderContainer = styled.nav`
  height: 72px;
  width: 100%;
  background: transparent;
  background: ${({ isAlwaysBlue }) =>
    isAlwaysBlue ? "#101c3d" : "transparent"};
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: padding 0.25s ease-in-out, background-color 0.25s ease-in-out,
    opacity 0.25s ease-in-out;

  svg {
    color: ${colors.white};
    transition: color 0.25s ease-in-out;
  }

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      padding: ${rem(6)} 0;
      background-color: #101c3d;
      box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);

      svg {
        color: red;
      }
    `}

  ul {
    list-style-type: none;

    li {
      display: inline;

      &:not(:last-child) {
        margin-right: 48px;
      }
    }
  }
`
const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    &:hover {
      cursor: pointer;
    }
  }
`

const Lists = styled.ul`
  ${mediaQuery("lg")} {
    display: none;
  }
`
const BurgerContainer = styled.div`
  z-index: 1;
  display: none;

  ${mediaQuery("lg")} {
    display: block;
  }
`

const StyledLink = styled(Link)`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.528px;
  color: ${colors.white};
  transition: color 0.25s ease-in-out;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: #ef5da8;
  }
`
export const Header = ({ isAlwaysBlue }) => {
  const [open, setOpen] = useState(false)
  const { scrollY } = useScroll()
  const location = useLocation()
  const isScrolled = scrollY >= 12

  useEffect(() => open && setOpen(false), [scrollY])
  useEvent("resize", () => setOpen(false))

  return (
    <HeaderContainer isScrolled={isScrolled} isAlwaysBlue={isAlwaysBlue}>
      <Container>
        <Navigation>
          <StyledLink to="/">
            <LogoSvg />
          </StyledLink>
          <BurgerContainer>
            <Burger open={open} setOpen={setOpen} isScrolled={isScrolled} />
          </BurgerContainer>

          <Menu open={open} setOpen={setOpen} scrollY={scrollY} />

          <Lists>
            <li>
              <StyledLink to="/apie-mus">Apie mus</StyledLink>
            </li>
            <li>
              <StyledLink to="/paslaugos">Paslaugos</StyledLink>
            </li>
            <li>
              <StyledLink to="/kontaktai">Kontaktai</StyledLink>
            </li>
            <li>
              <StyledLink to="/pasiulymai">Pasiūlymai</StyledLink>
            </li>
            <li>
              <Button onClick={() => scrollTo("#form", "center")}>
                Užklausa
              </Button>
            </li>
          </Lists>
        </Navigation>
      </Container>
    </HeaderContainer>
  )
}
