import React, { forwardRef } from "react"
import { rem } from "polished"
import { colors, mediaQuery } from "../../../theme"
import styled, { css } from "styled-components"

const InputContainer = styled.div`
  padding: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  background: #f7f9fb;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 40px;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  ${mediaQuery("sm")} {
    padding: ${rem(14)};
  }

  ${mediaQuery("md")} {
    font-size: 14px;
    padding: 12px;
  }
`

const InputStyled = styled.input`
  border: none;
  background-color: transparent;
  height: 100%;
  outline: none;
  line-height: 1;
  margin-left: 8px;
  width: 100%;
  font-family: DM Sans;

  &::placeholder {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.528px;
    color: #9ea1ab;
  }

  ${mediaQuery("md")} {
    &::placeholder {
      font-size: 14px;
    }
  }
`

export const Input = forwardRef(
  (
    {
      onChange,
      onBlur,
      placeholder,
      type = "text",
      name,
      error,
      obj,
      ...restProps
    },
    ref
  ) => {
    const isTextarea = type === "textarea"
    const textareaProps = isTextarea ? { as: "textarea", rows: 4 } : {}

    return (
      <InputContainer isTextarea={isTextarea} error={error}>
        <InputStyled
          type={type}
          name={name}
          id={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          {...textareaProps}
          {...restProps}
        />
      </InputContainer>
    )
  }
)
