import React from "react"
import { Header } from "./header"
import { Footer } from "./footer"
import styled from "styled-components"
import { mediaQuery } from "../../theme"
import { useStaticQuery, graphql } from "gatsby"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"

const HolyGrailBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const HolyGrail = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
export const Layout = ({ children, isAlwaysBlue = false }) => {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)
  return (
    <SEOContext.Provider value={{ global: seo }}>
      <HolyGrail>
        <Header isAlwaysBlue={isAlwaysBlue} />
        <HolyGrailBody>{children}</HolyGrailBody>
        <Footer />
      </HolyGrail>
    </SEOContext.Provider>
  )
}
