import React from "react"
import { createGlobalStyle } from "styled-components"

export const GlobalCss = createGlobalStyle`
  html {
    background: #fff;
    font-family: DM Sans;
  }

  a {
    text-decoration: none;
  }
`
