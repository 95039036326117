import { rem } from "polished"
import styled from "styled-components"
import { mediaQuery } from "../theme/breakpoints"

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${rem(1300)};
  width: 100%;
  padding: 0 ${rem(16)};
  box-sizing: border-box;

  ${mediaQuery("xl")} {
    padding: 0 ${rem(40)};
  }

  ${mediaQuery("md")} {
    padding: 0 ${rem(32)};
  }

  ${mediaQuery("sm")} {
    padding: 0 ${rem(16)};
  }
`
