import React from "react"
import styled, { css } from "styled-components"
import { colors } from "../../theme"

const ButtonContainer = styled.button`
  box-sizing: border-box;
  padding: 10px 24px;
  background: ${({ isBlue }) => (isBlue ? "#416FF4" : "transparent")};
  color: ${colors.white};
  border: 1px solid ${({ isBlue }) => (isBlue ? "transparent" : colors.white)};
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  width: max-content;

  letter-spacing: -0.528px;
  color: ${colors.white};
  transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border 0.25s ease-in-out;

  &:hover {
    background: ${({ isBlue }) => (!isBlue ? "#416FF4" : "")};
    border: 1px solid ${({ isBlue }) => (!isBlue ? "#416FF4" : "#416FF4")};
    transform: ${({ isBlue }) => (!isBlue ? "" : "scale(1.1)")};
  }

  &:disabled {
    display: none;
  }
`

export const Button = ({ isBlue = false, children, onClick, disabled }) => {
  return (
    <ButtonContainer isBlue={isBlue} onClick={onClick} disabled={disabled}>
      {children}
    </ButtonContainer>
  )
}
