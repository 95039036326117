export const colors = {
  white: "#FFFFFF",
  pink1: "#EF5DA8",
  pink2: "#F178B6",
  gray1: "#687497",
  black1: "#0D152E",
  gray2: "#81838C",
  blue: "#101c3d",
  gray900: "18191F",
}
