import React, { useState, useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { Input } from "./input"
import { useForm } from "react-hook-form"
import { colors, mediaQuery } from "../../theme"
import axios from "axios"
import { graphql, useStaticQuery } from "gatsby"

const FormContainer = styled.form`
  background: #ffffff;
  box-shadow: 0px 23px 78px rgba(0, 0, 0, 0.0901961);
  border-radius: 22px;
  padding: 50px;
  box-sizing: border-box;
  width: 740px;
  position: absolute;
  top: 0;

  ${mediaQuery("md")} {
    width: 90%;
    padding: 60px;
  }
  ${mediaQuery("sm")} {
    width: 95%;
    padding: 40px 30px;
  }
`
const SubmitButton = styled.button`
  width: 100%;
  background: #416ff4;
  border-radius: 30px;
  padding: 16px 0;
  border: none;

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.528px;
  color: #ffffff;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  ${mediaQuery("md")} {
    font-size: 14px;
    padding: 12px 0;
  }
`

const Text = styled.div`
  strong {
    color: #ef5da8;
    text-decoration: none;
  }
  h4 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 55px;
    text-align: center;
    letter-spacing: -1.386px;
    color: #0d152e;
    margin-bottom: 16px;
  }

  p {
    text-align: center;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.66px;
    color: #81838c;
    margin-bottom: 16px;
  }

  ${mediaQuery("md")} {
    h4 {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 8px;
    }
    p {
      font-size: 15px;
      line-height: 25px;
    }
  }
  ${mediaQuery("sm")} {
    h4 {
      font-size: 26px;
    }
    p {
      font-size: 13px;
    }
  }
`
const DropdownWrapper = styled.div`
  position: relative;
  &::before {
    content: "〱";
    font-size: 18px;
    top: 34%;
    right: 20px;
    position: absolute;
    color: #9ea1ab;
    transform: rotate(-90deg);
    pointer-events: none;

    ${mediaQuery("sm")} {
      font-size: 15px;
    }
  }
`
const Dropdown = styled.select`
  padding: 16px 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  background: #f7f9fb;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 107px;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.528px;
  cursor: pointer;

  &:invalid {
    color: #9ea1ab;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}

  ${mediaQuery("md")} {
    font-size: 14px;
    padding: 12px 18px;
  }
`

export const Form = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ mode: "onTouched" })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
    }
  }

  const handleOnSubmit = (data, e) => {
    e.target.reset()
    setValue("Paslauga", "")
    setServerState({
      submitting: true,
      status: { msg: "Užklausa siunčiama" },
    })
    axios({
      method: "post",
      url: "https://getform.io/f/54229065-f0db-4316-bb74-2c59d5c321f6",
      data,
    })
      .then(r => {
        handleServerResponse(true, "Užklausa sėkmingai pateikta!")
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error)
      })
  }

  const {
    wpPage: { ACF_homepage },
    contact: { email, phone },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ACF_homepage {
          services {
            title
          }
          form {
            title
            text
          }
        }
      }
      contact: wpPage(slug: { eq: "kontaktai" }) {
        ACF_contacts {
          email
          phone
        }
      }
    }
  `)
  const title = ACF_homepage.form.title
  const text = ACF_homepage.form.text
  const services = ACF_homepage.services

  const Vardas = register("Vardas", { required: true })
  const Tel = register("Tel", { required: true })
  const Email = register("Email", { required: true })
  const Pastabos = register("Pastabos", { required: true })
  return (
    <>
      <FormContainer onSubmit={handleSubmit(handleOnSubmit)} id="form">
        <Text>
          <h4>{title}</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </Text>
        <Input
          name="Vardas"
          placeholder="Vardas"
          onChange={Vardas.onChange}
          onBlur={Vardas.onBlur}
          ref={Vardas.ref}
          error={errors["Vardas"]}
        ></Input>
        <Input
          name="Tel"
          type="number"
          placeholder="Tel. numeris"
          error={errors["Tel"]}
          onChange={Tel.onChange}
          onBlur={Tel.onBlur}
          ref={Tel.ref}
        ></Input>
        <Input
          name="Email"
          type="text"
          placeholder="El. Paštas"
          error={errors["Email"]}
          onChange={Email.onChange}
          onBlur={Email.onBlur}
          ref={Email.ref}
        ></Input>

        <DropdownWrapper>
          <Dropdown
            {...register("Paslauga", { required: true })}
            name="Paslauga"
            required
          >
            <option value="" disabled selected>
              Pasirinkite paslaugą
            </option>
            {services.map((service, index) => (
              <option key={index}>{service.title}</option>
            ))}
          </Dropdown>
        </DropdownWrapper>
        <Input
          name="Pastabos"
          placeholder="Pastabos"
          error={errors["Pastabos"]}
          type="textarea"
          onChange={Pastabos.onChange}
          onBlur={Pastabos.onBlur}
          ref={Pastabos.ref}
        ></Input>

        <SubmitButton type="submit">
          {serverState.status?.msg ?? "Siųsti užklausą"}
        </SubmitButton>
      </FormContainer>
    </>
  )
}
