import React from "react"
import styled from "styled-components"
import { colors, Container, mediaQuery } from "../../../theme"
import { graphql, useStaticQuery } from "gatsby"
import { ReactComponent as LogoSvg } from "../../../images/logo.svg"
import { ReactComponent as FbSvg } from "../../../images/fb.svg"

import { Form } from "../../form"
import { Link } from "gatsby"

import CookieConsent from "react-cookie-consent"

const FooterContainer = styled.div`
  width: 100%;
  height: 728px;
  background: linear-gradient(180deg, #f178b6 50%, #101c3d 86.24%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    margin-bottom: 24px;
  }
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 64px;

  ${mediaQuery("sm")} {
    margin-bottom: 32px;
  }
`

const Lists = styled.div`
  ul {
    list-style-type: none;

    li {
      &:not(:last-child) {
        margin-right: 64px;
      }
      display: inline;

      a {
        font-family: DM Sans;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: ${colors.white};
        transition: color 0.25s ease-in-out;

        &:hover {
          cursor: pointer;
          color: ${colors.blue900};
        }
      }
    }
  }
  ${mediaQuery("md")} {
    ul {
      li {
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }

  ${mediaQuery("sm")} {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      li {
        text-align: center;
        margin-bottom: 8px;
        &:not(:last-child) {
          margin-right: 16px;
        }
        a {
          font-size: 13px;
        }
      }
    }
  }
`
const FooterWrapper = styled.div`
  background: transparent;
  position: relative;
  height: 965px;
  display: flex;
  align-items: flex-end;
  margin-top: 96px;

  ${mediaQuery("sm")} {
    height: 900px;
  }
`

const StyledLink = styled(Link)`
  font-family: DM Sans;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
  transition: color 0.25s ease-in-out;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    color: ${colors.blue900};
    opacity: 0.6;
  }

  ${mediaQuery("sm")} {
    font-size: 13px;
  }
`

const FbLogo = styled.a`
  cursor: pointer;
  &:hover {
    svg {
      opacity: 0.6;
    }
  }
`

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            id
            ACF_homepage {
              footer {
                email
              }
            }
          }
        }
      }
    }
  `)
  const email = data.allWpPage.edges[0].node.ACF_homepage.footer.email
  const mailto = `mailto:${email}`

  return (
    <FooterWrapper>
      <FooterContainer>
        <Form />
        <FooterContent>
          <LogoSvg />
          <FbLogo href="https://www.facebook.com/">
            <FbSvg />
          </FbLogo>
          <Lists>
            <ul>
              <li>
                <StyledLink to="/apie-mus">Apie mus</StyledLink>
              </li>
              <li>
                <StyledLink to="/paslaugos">Paslaugos</StyledLink>
              </li>
              <li>
                <StyledLink to="/kontaktai">Kontaktai</StyledLink>
              </li>
              <li>
                <StyledLink to="/pasiulymai">Pasiūlymai</StyledLink>
              </li>
              <li>
                <StyledLink to="/privatumo-politika">
                  Privatumo politika
                </StyledLink>
              </li>
            </ul>
          </Lists>
        </FooterContent>
      </FooterContainer>

      <CookieConsent
        buttonText="Sutinku"
        style={{ background: "#101c3d", borderTop: "1px solid white" }}
        buttonStyle={{
          color: "white",
          backgroundColor: "#416FF4",
          fontSize: "14px",
          padding: "0.5rem 1.5rem",
          borderRadius: "24px",
        }}
      >
        Šioje svetainėje naudojami slapukai, kad užtikrintume geriausią patirtį
        mūsų svetainėje.{" "}
        <a
          style={{
            color: "white",
            float: "right",
            textDecoration: "underline",
          }}
          href="/privatumo-politika"
        >
          Skaityti daugiau
        </a>
      </CookieConsent>
    </FooterWrapper>
  )
}
